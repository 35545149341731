import { AiOutlineSetting } from 'react-icons/ai';
import { FiLogOut } from 'react-icons/fi';
import { TbChartInfographic } from 'react-icons/tb';
import { MdNotificationsNone } from 'react-icons/md';
import { FaCogs, FaRegUserCircle } from 'react-icons/fa';
import { RiMailSendLine } from 'react-icons/ri';
import SideMenuLink from '../SideMenuLink/SideMenuLink';
import useLogout from '../../../../CustomHooks/useLogout';
import { frontendConstants } from '../../../../constants/constants';
import { useSelector } from 'react-redux';
import { selectAllAuthValues } from '../../../../features/auth/authSlice';
import { RiAdminFill } from 'react-icons/ri';
import { useGetContractsQuery } from '../../../../features/incidents/incidentsSlice';
import { useGetPageSettingsQuery } from '../../../../features/Settings/settingsSlice';
import { Settings } from '../../../../tsTypes/enums';
import { MdOutlineDashboard } from 'react-icons/md';
import classes from './SideMenuLinks.module.scss';
import getUserContractsResult from '../../../../utils/ContractUsersUtils/getUserContractsResult';
import { useTranslation } from 'react-i18next';
import useCurrentContract from '../../../../CustomHooks/useCurrentContract';
import { IUserContract } from '../../../../tsTypes/interfaces';

interface IProps {
  isExpanded?: boolean;
  onSelect?: () => void;
}

const SideMenuLinks = ({ isExpanded, onSelect }: IProps) => {
  const iconProps = { size: 23 };
  const [logout] = useLogout();
  const { userAuthorizations } = useSelector(selectAllAuthValues);
  const { t } = useTranslation();
  let hasAdminAuth = false;
  let hasActionsAuth = false;

  let hasLogoutLink = false;
  let hasNotificationsLink = false;
  let hasReportLink = false;
  let hasServicesLink = false;
  let hasSettingsLink = false;
  let hasStatisticsLink = false;
  let hasUserAdminLink = false;
  let hasUserLink = false;

  const { data: userContractsData, isLoading } =
    useGetContractsQuery('contracts');

  const { hasContracts } = getUserContractsResult(userContractsData);

  const { data: settings } = useGetPageSettingsQuery(Settings.sidedrawer, {
    skip: isLoading,
  });

  const currentContract = useCurrentContract();
  let currentContractServiceTypeId = '';
  let isSwimmingPoolContract = false;

  if (
    userContractsData &&
    'contracts' in userContractsData &&
    Array.isArray(userContractsData.contracts) &&
    userContractsData.contracts.length > 0
  ) {
    const userContracts: IUserContract[] = userContractsData.contracts;

    const currentContractData = userContracts.find(
      (contract) => contract.contractId === currentContract
    );

    if (currentContractData) {
      currentContractServiceTypeId = currentContractData.serviceTypeId;
    }
  }

  if (
    currentContractServiceTypeId &&
    currentContractServiceTypeId === 'k3yxkY5CjzGvPBxzJlddwsUNRAHXS2Ci-0026'
  ) {
    isSwimmingPoolContract = true;
  }

  if (
    settings &&
    typeof settings === 'object' &&
    'hasLogoutLink' in settings &&
    'hasNotificationsLink' in settings
  ) {
    hasLogoutLink = settings.hasLogoutLink;
    hasNotificationsLink = settings.hasNotificationsLink;
    hasReportLink = settings.hasReportLink;
    hasServicesLink = settings.hasServicesLink;
    hasSettingsLink = settings.hasSettingsLink;
    hasStatisticsLink = settings.hasStatisticsLink;
    hasUserAdminLink = settings.hasUserAdminLink;
    hasUserLink = settings.hasUserLink;
  }

  if (
    userAuthorizations &&
    typeof userAuthorizations === 'object' &&
    'hasAuthToActions' in userAuthorizations &&
    'hasAuthtoAdmin' in userAuthorizations
  ) {
    hasAdminAuth = userAuthorizations.hasAuthtoAdmin;
    hasActionsAuth = userAuthorizations.hasAuthToActions;
  }

  return (
    <>
      <div className={classes.UpperIcons}>
        <>
          <SideMenuLink
            Icon={<MdOutlineDashboard />}
            id='dashboard'
            label={t('translations:Dashboard')}
            to={frontendConstants.pages.dashboard}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:Dashboard')}
            onSelect={onSelect}
          />
          <hr />
        </>
        {hasContracts && hasStatisticsLink && !isSwimmingPoolContract ? (
          <SideMenuLink
            Icon={<TbChartInfographic />}
            id='statistics'
            label={t('translations:Statistics')}
            to={frontendConstants.pages.statistics}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:Statistics')}
            onSelect={onSelect}
          />
        ) : null}
        {hasContracts && hasNotificationsLink ? (
          <SideMenuLink
            Icon={<MdNotificationsNone />}
            id='messages'
            label={t('translations:Notifications')}
            to={frontendConstants.pages.notifications}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:Notifications')}
            onSelect={onSelect}
          />
        ) : null}
        {hasActionsAuth &&
        hasReportLink &&
        hasContracts &&
        !isSwimmingPoolContract ? (
          <SideMenuLink
            Icon={<RiMailSendLine />}
            id='outbox'
            label={t('translations:My_Reports')}
            to={frontendConstants.pages.outbox}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title='Send Incident Reports'
            onSelect={onSelect}
          />
        ) : null}
        {hasUserLink ? (
          <SideMenuLink
            Icon={<FaRegUserCircle />}
            id='usersettings'
            label={t('translations:User')}
            to={frontendConstants.pages.user}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:User_Settings')}
            onSelect={onSelect}
          />
        ) : null}
        {hasAdminAuth && hasUserAdminLink && hasContracts ? (
          <SideMenuLink
            Icon={<RiAdminFill />}
            id='useradmin'
            label={t('translations:Admin')}
            to={frontendConstants.pages.userAdmin}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:User_Administrator_Page')}
            onSelect={onSelect}
          />
        ) : null}
        {(!hasContracts || hasAdminAuth) && hasServicesLink ? (
          <SideMenuLink
            Icon={<FaCogs />}
            id='service-registration'
            label={t('translations:Services')}
            to={frontendConstants.pages.serviceRegistration}
            iconProps={iconProps}
            isExpanded={isExpanded}
            onSelect={onSelect}
            title={t('translations:Register_service')}
          />
        ) : null}
      </div>
      <div className={classes.LowerIcons}>
        {hasSettingsLink ? (
          <SideMenuLink
            Icon={<AiOutlineSetting />}
            id='settings'
            label={t('translations:Settings')}
            to={frontendConstants.pages.settings}
            iconProps={iconProps}
            isExpanded={isExpanded}
            title={t('translations:Settings')}
            onSelect={onSelect}
          />
        ) : null}

        {hasLogoutLink ? (
          <SideMenuLink
            Icon={<FiLogOut />}
            id='logout'
            label={t('translations:Logout')}
            iconProps={iconProps}
            isExpanded={isExpanded}
            onClick={logout}
            title={t('translations:Logout')}
          />
        ) : null}
      </div>
    </>
  );
};

export default SideMenuLinks;
