import { useState } from 'react';
import { useMap } from 'react-map-gl';
import useDirections from '../../../../CustomHooks/useDirections';
import {
  ILocation,
  INullableLocation,
} from '../../../../tsTypes/types.components';
import { IPoint } from '../../../../tsTypes/types.model';
import IncidentMarker from '../../../MapSWComponents/IncidentMarker/IncidentMarker';
import MapDirectionLayer from '../../../MapSWComponents/MapDirectionLayer/MapDirectionLayer';
import MapDirectionsBoard from '../../../MapSWComponents/MapDirectionsBoard/MapDirectionsBoard';
import MapSW from '../../../MapSWComponents/MapSW';
import NoData from '../../../UI/NoData/NoData';
import { IContractIncident } from '../../../../tsTypes/interfaces';
import { Coordinates } from '../../../../tsTypes/types';
import FlyTo from '../../../UI/FlyTo/FlyTo';

interface IProps {
  incidents: IContractIncident[] | null;
  centerCoords: Coordinates | [];
}

const ListMap = ({ incidents, centerCoords }: IProps) => {
  const [showBoard, setShowBoard] = useState(false);
  //this will be the location of user from DB, or current location of user
  const [startLocation, setStartLocation] = useState<INullableLocation>({
    latitude: null,
    longitude: null,
  });
  const [showPopUpIndex, setShowPopUpIndex] = useState<number | null>(null);
  const [makeOpac, setMakeOpac] = useState(false);
  const map = useMap();

  const [selectedIncident, setSelectedIncident] = useState<Pick<
    IPoint,
    'Coordinates' | 'Type'
  > | null>(null);

  const { latitude: latitudeStart, longitude: longitudeStart } = startLocation
    ? startLocation
    : { latitude: null, longitude: null };
  const { latitude: latitudeEnd, longitude: longitudeEnd } = selectedIncident
    ? selectedIncident.Coordinates
    : { latitude: null, longitude: null };

  const { directions, steps, clearState, restartFetch } = useDirections(
    { latitudeStart, longitudeStart, latitudeEnd, longitudeEnd },
    () => {
      setSelectedIncident(null);
      setShowBoard(false);
    }
  );

  if (!incidents) {
    return <NoData message='No incident data found.' />;
  }

  const showHideBoardOnButtonClickHandler = () => {
    setShowBoard((prevState) => !prevState);
  };

  // const targetPointDataHandler = (
  //   pointData: Pick<IPoint, 'Coordinates' | 'Type'>
  // ) => {
  //   setSelectedIncident(pointData);
  //   setShowBoard(true);
  // };

  const getUserCurrentLocationHandler = (userLocation: ILocation) => {
    setStartLocation(userLocation);
    restartFetch();
  };

  const getUserCurrentOrganizationLocationHandler = (
    organizationLocation: ILocation
  ) => {
    setStartLocation(organizationLocation);
  };

  // const clearDirectionsHandler = () => {
  //   clearState();
  // };

  const popupShowHandler = (incident: IContractIncident, index: number) => {
    if (
      incident &&
      incident.coordinates &&
      Array.isArray(incident.coordinates) &&
      incident.coordinates.length === 2
    ) {
      setShowPopUpIndex(index);

      if (map && map.CyprusMap) {
        map.CyprusMap.flyTo({
          center: [incident.coordinates[1], incident.coordinates[0]],
          zoom: 15,
        });
      }

      if (map && map.current && !map.CyprusMap) {
        map.current.flyTo({
          center: [incident.coordinates[1], incident.coordinates[0]],
          zoom: 15,
        });
      }
    }
  };

  const popupCloseHandler = () => {
    setShowPopUpIndex(null);
    setMakeOpac(false);

    const latitude =
      centerCoords !== undefined && centerCoords.length === 2
        ? centerCoords[0]
        : 35.1264;
    const longitude =
      centerCoords !== undefined && centerCoords.length === 2
        ? centerCoords[1]
        : 33.4299;

    if (map && map.CyprusMap) {
      map.CyprusMap.flyTo({
        center: [longitude, latitude],
        zoom: 10,
      });
    }

    if (map && map.current && !map.CyprusMap) {
      map.current.flyTo({
        center: [longitude, latitude],
        zoom: 10,
      });
    }

    clearState();
  };

  return (
    <MapSW>
      {incidents.map((incident, index) => (
        <IncidentMarker
          incidentData={incident}
          key={incident._id}
          onShowPopup={() => popupShowHandler(incident, index)}
          showPopUp={showPopUpIndex === index}
          onClosePopup={popupCloseHandler}
          makeOpac={makeOpac}
        />
      ))}
      <MapDirectionLayer
        startLocation={startLocation}
        endLocation={
          selectedIncident
            ? selectedIncident.Coordinates
            : { latitude: null, longitude: null }
        }
        directions={directions}
      />
      <MapDirectionsBoard
        stepsData={steps}
        show={showBoard}
        selectedIncident={selectedIncident}
        onClick={showHideBoardOnButtonClickHandler}
        onGetUserLocation={getUserCurrentLocationHandler}
        onGetCurrentOrganizationLocation={
          getUserCurrentOrganizationLocationHandler
        }
      />
      {centerCoords !== undefined && centerCoords.length === 2 ? (
        <FlyTo coordinates={centerCoords} zoomLevel={9.7} />
      ) : null}
    </MapSW>
  );
};

export default ListMap;
