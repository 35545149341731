import React, { useRef } from 'react';
import { IGeoJSON } from '../../../../tsTypes/interfaces';
import { Coordinates } from '../../../../tsTypes/types';
import {
  AnyLayer,
  HeatmapLayer,
  Layer,
  Map,
  MapRef,
  Source,
} from 'react-map-gl';
import { Maps } from '../../../../tsTypes/enums';
import FlyTo from '../../../UI/FlyTo/FlyTo';

interface IProps {
  incidents: IGeoJSON | null;
  centerCoords: Coordinates | [];
  height?: number;
}

const SwimmingPoolHeatMap = ({ incidents, centerCoords, height }: IProps) => {
  const mapRef = useRef<MapRef | null>(null);

  if (!incidents) {
    return null;
  }

  const heatMapLayer: HeatmapLayer = {
    id: 'incident-heat',
    type: 'heatmap',
    maxzoom: 15,
    minzoom: 9,
    paint: {
      // Increase the heatmap weight based on frequency and property magnitude
      'heatmap-weight': {
        property: 'dbh',
        type: 'exponential',
        stops: [
          [1, 0],
          [62, 1],
        ],
      },
      // Increase the heatmap color weight weight by zoom level
      // heatmap-intensity is a multiplier on top of heatmap-weight
      'heatmap-intensity': {
        stops: [
          [11, 1],
          [15, 3],
        ],
      },
      // Color ramp for heatmap.  Domain is 0 (low) to 1 (high).
      // Begin color ramp at 0-stop with a 0-transparancy color
      // to create a blur-like effect.
      'heatmap-color': [
        'interpolate',
        ['linear'],
        ['heatmap-density'],
        0,
        'rgba(33,102,172,0)',
        0.2,
        'rgb(103,169,207)',
        0.4,
        'rgb(209,229,240)',
        0.6,
        'rgb(253,219,199)',
        0.8,
        'rgb(239,138,98)',
        1,
        'rgb(178,24,43)',
      ],
      // Adjust the heatmap radius by zoom level
      'heatmap-radius': {
        stops: [
          [11, 15],
          [15, 20],
        ],
      },
      // Transition from heatmap to circle layer by zoom level
      'heatmap-opacity': {
        default: 1,
        stops: [
          [14, 1],
          [15, 0],
        ],
      },
    },
  };

  const circleLayer: AnyLayer = {
    id: 'incident-points',
    type: 'circle',
    maxzoom: 22,
    minzoom: 14,
    paint: {
      // increase the radius of the circle as the zoom level and dbh value increases
      'circle-radius': {
        property: 'dbh',
        type: 'exponential',
        stops: [
          [{ zoom: 15, value: 1 }, 5],
          [{ zoom: 15, value: 62 }, 10],
          [{ zoom: 22, value: 1 }, 20],
          [{ zoom: 22, value: 62 }, 50],
        ],
      },
      'circle-color': {
        property: 'dbh',
        type: 'exponential',
        stops: [
          [0, 'rgba(236,222,239,0)'],
          [10, 'rgb(236,222,239)'],
          [20, 'rgb(208,209,230)'],
          [30, 'rgb(166,189,219)'],
          [40, 'rgb(103,169,207)'],
          [50, 'rgb(28,144,153)'],
          [60, 'rgb(1,108,89)'],
        ],
      },
      'circle-stroke-color': 'white',
      'circle-stroke-width': 1,
      'circle-opacity': {
        stops: [
          [14, 0],
          [15, 1],
        ],
      },
    },
  };

  return (
    <Map
      initialViewState={{
        latitude: centerCoords[0],
        longitude: centerCoords[1],
        zoom: 10,
      }}
      mapStyle={Maps.navigationMap}
      mapboxAccessToken='pk.eyJ1IjoiYXl0YWNnMjYiLCJhIjoiY2xobThyNmlhMTl2YjNxbnV2YXBreW04eCJ9.sVAdyc54G2c7DPtumtLTDg'
      style={{
        height: `${height ? `${height}px` : '650px'}`,
        width: '86dvw',
        maxWidth: 'inherit',
        margin: '0 auto',
      }}
      maxZoom={18.5}
      refreshExpiredTiles={true}
      ref={mapRef}
      maxTileCacheSize={500}
      id='heat_map'
    >
      <Source id='swimming_pools_heatmap' type='geojson' data={incidents}>
        <Layer {...heatMapLayer} />
        <Layer {...circleLayer} />
      </Source>

      {centerCoords !== undefined && centerCoords.length === 2 ? (
        <FlyTo coordinates={centerCoords} zoomLevel={11} />
      ) : null}
    </Map>
  );
};

export default SwimmingPoolHeatMap;
